/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                var viewportWidth = $(window).width();

                // Fastclick
                FastClick.attach(document.body);

                console.log($.fancybox);
                //$.fancybox.open('<div class="message"><h2>Hello!</h2><p>You are awesome!</p></div>');

                // Remove empty p tags
                $('p:empty').remove();

                // External links
                $('a').filter(function () {
                    return this.hostname && this.hostname !== location.hostname;
                }).addClass('external').attr('target', '_blank');

                // Navigation
                $('.c-toggle--nav').click(function (e) {
                    e.preventDefault();

                    $('.o-wrap').toggleClass('is-open');
                });

                // Navigation Dropdown
                $('.js-toggle-dropdown').click(function () {
                    $(this).parent().toggleClass('is-expanded').siblings().removeClass('is-expanded');
                });

                // Header
                if (viewportWidth > 1023) {
                    var headerHeight = $('.c-header').outerHeight(true);

                    $('body').css('padding-top', headerHeight);
                }

                // Scroll
                var elWrap = $('.o-wrap');

                // Add scrolling class to wrap
                $(window).scroll(function () {
                    var scrollX = $(this).scrollTop();

                    if (scrollX > 5) {
                        elWrap.addClass('is-scrolling');
                    } else {
                        elWrap.removeClass('is-scrolling');
                    }
                });

                // Scroll to container
                $('.has-scroll').click(function (e) {
                    e.preventDefault();

                    var container = $(this).attr('href');

                    $('html, body').animate({
                        scrollTop: $(container).offset().top
                    }, 1500);
                });

                // Down
                $('.c-scroll--down').click(function (e) {
                    e.preventDefault();

                    $('html, body').animate({
                        scrollTop: $('.c-section--intro').offset().top
                    }, 1500);
                });

                // Slick
                // Post Items
                if (viewportWidth < 768) {
                    $('.js-post-items').slick({
                        autoplay: true,
                        arrows: false,
                        infinite: true,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    });
                }

                // Hero
                var elHero = $('.c-hero'),
                    mobileBackground = elHero.data('mobile'),
                    desktopBackground = elHero.data('desktop');

                if (viewportWidth > 1024) {
                    elHero.css('background-image', 'url(' + desktopBackground + ')');
                } else {
                    elHero.css('background-image', 'url(' + mobileBackground + ')');
                }

                // Press Filter
                var pressFilter = $('.c-form--filter');

                if (pressFilter.length) {
                    pressFilter.on('change', function () {
                        location.href = $(this).val();
                    });
                }
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
